import Layout from "Advertiser/Layout"
import Market from "Advertiser/Pages/Market"
import Payment from "Advertiser/Pages/Payment"
import Request from "Advertiser/Pages/Request"
import Statistic from "Advertiser/Pages/Statistic"
import Stream from "Advertiser/Pages/Stream"
import { Navigate } from "react-router-dom"
import Dashboard from "Advertiser/Pages/Dashboard"
import Pages from "./Pages"

const AdvertiserRoutes = [
  {
    path: "/admin",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "market",
        element: <Market />,
      },
      {
        path: "stream",
        element: <Stream />,
      },
      {
        path: "stats",
        element: <Statistic />,
      },
      {
        path: "payment",
        element: <Payment />,
      },

      {
        path: "request",
        element: <Request />,
      },
      {
        path: "profile",
        element: <Pages.Profile2 />,
        children: [
          {
            index: true,
            element: <Pages.Profile2Account />,
          },
          {
            path: "telegram",
            element: <Pages.Profile2Telegram />,
          },
          {
            path: "targat-api",
            element: <Pages.Profile2TargatApi />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="/admin" />,
      },
    ],
  },
]

export default AdvertiserRoutes
