import { ReactComponent as October } from "assets/images/icons/october-logo.svg"
import { ReactComponent as Target } from "assets/images/icons/target.svg"
import { ReactComponent as Dashboard } from "assets/images/icons/dashboard-icon.svg"
import { ReactComponent as Truck } from "assets/images/icons/truck.svg"
import { ReactComponent as List } from "assets/images/icons/list.svg"
import { ReactComponent as Plus } from "assets/images/icons/plus.svg"
import { ReactComponent as Search } from "assets/images/icons/search.svg"
import { ReactComponent as Filter } from "assets/images/icons/filter.svg"
import { ReactComponent as ArrowRightLong } from "assets/images/icons/arrow-right-long.svg"
import { ReactComponent as Upload } from "assets/images/icons/upload.svg"
import { ReactComponent as XIcon } from "assets/images/icons/x.svg"
import { ReactComponent as CircleX } from "assets/images/icons/circle-xmark.svg"
import { ReactComponent as FileCheck } from "assets/images/icons/file-check.svg"
import { ReactComponent as AngleDown } from "assets/images/icons/angle-down.svg"
import { ReactComponent as PagePlus } from "assets/images/icons/page-plus.svg"
import { ReactComponent as ListPlus } from "assets/images/icons/list-plus.svg"
import { ReactComponent as Delete } from "assets/images/icons/delete.svg"
import { ReactComponent as FileMinus } from "assets/images/icons/file-minus.svg"
import { ReactComponent as Category } from "assets/images/icons/category.svg"
import { ReactComponent as Cash } from "assets/images/icons/cash.svg"
import { ReactComponent as Calculator } from "assets/images/icons/calculator.svg"
import { ReactComponent as ChartArea } from "assets/images/icons/chart-area.svg"
import { ReactComponent as TableList } from "assets/images/icons/table-list.svg"
import { ReactComponent as Bitcoin } from "assets/images/icons/bitcoin.svg"
import { ReactComponent as Mastercard } from "assets/images/icons/mastercard.svg"
import { ReactComponent as History } from "assets/images/icons/history.svg"
import { ReactComponent as Comments } from "assets/images/icons/comments.svg"
import { ReactComponent as Minus } from "assets/images/icons/minus.svg"
import { ReactComponent as Eraser } from "assets/images/icons/eraser.svg"
import { ReactComponent as Check } from "assets/images/icons/check.svg"
import { ReactComponent as Exit } from "assets/images/icons/exit.svg"
import { ReactComponent as User } from "assets/images/icons/user.svg"
import { ReactComponent as UserIcon } from "assets/images/icons/userIcon.svg"
import { ReactComponent as Group } from "assets/images/icons/user-group.svg"
import { ReactComponent as LifeRing } from "assets/images/icons/life-ring.svg"
import { ReactComponent as NoData } from "assets/images/icons/no-data.svg"
import { ReactComponent as Permission } from "assets/images/icons/shield-check.svg"
import { ReactComponent as Sale } from "assets/images/icons/badge-dollar.svg"
import { ReactComponent as IdCard } from "assets/images/icons/id-card.svg"
import { ReactComponent as Phone } from "assets/images/icons/phone.svg"
import { ReactComponent as Taxi } from "assets/images/icons/taxi.svg"
import { ReactComponent as Clock } from "assets/images/icons/clock.svg"
import { ReactComponent as XLight } from "assets/images/icons/x-light.svg"
import { ReactComponent as BoxArchive } from "assets/images/icons/box-archive.svg"
import { ReactComponent as Spam } from "assets/images/icons/spam.svg"
import { ReactComponent as Anchor } from "assets/images/icons/anchor.svg"
import { ReactComponent as ListOL } from "assets/images/icons/list-ol.svg"
import { ReactComponent as Globe } from "assets/images/icons/globe.svg"
import { ReactComponent as Store } from "assets/images/icons/store.svg"
import { ReactComponent as Edit } from "assets/images/icons/edit.svg"
import { ReactComponent as Settings } from "assets/images/icons/Settings.svg"
import { ReactComponent as Info } from "assets/images/icons/info.svg"
import { ReactComponent as Copy } from "assets/images/icons/copy.svg"
import { ReactComponent as DoubleLines } from "assets/images/icons/double-lines.svg"
import { ReactComponent as Drag } from "assets/images/icons/drag.svg"
import { ReactComponent as Send } from "assets/images/icons/paper-plane.svg"
import { ReactComponent as RecordList } from "assets/images/icons/record-list.svg"
import { ReactComponent as CheckedWithCircle } from "assets/images/icons/CheckedWithCircle.svg"
import { ReactComponent as Pause } from "assets/images/icons/pause.svg"
import { ReactComponent as DoubleForward } from "assets/images/icons/double-forward.svg"
import { ReactComponent as PackerHistory } from "assets/images/icons/packer-history.svg"
import { ReactComponent as Bell } from "assets/images/icons/Bell.svg"
import { ReactComponent as ArrowLeftShort } from "assets/images/icons/ArrowLeftShort.svg"
import { ReactComponent as Location } from "assets/images/icons/Location.svg"
import { ReactComponent as PackerCategory } from "assets/images/icons/packer-category.svg"
import { ReactComponent as Clock2 } from "assets/images/icons/clock2.svg"
import { ReactComponent as FileLoading } from "assets/images/icons/FileLoading.svg"

import { ReactComponent as PackerUnchecked } from "assets/images/icons/packer-unchecked.svg"
import { ReactComponent as PackerChecked } from "assets/images/icons/packer-checked.svg"
import { ReactComponent as PackerDonate } from "assets/images/icons/PackerDonate.svg"
import { ReactComponent as PackerCoin } from "assets/images/icons/PackerCoin.svg"
import { ReactComponent as LocationMarker } from "assets/images/icons/LocationMarker.svg"
import { ReactComponent as Ticket } from "assets/images/icons/Ticket.svg"
import { ReactComponent as Lock } from "assets/images/icons/Lock.svg"
import { ReactComponent as LockOpen } from "assets/images/icons/LockOpen.svg"
import { ReactComponent as Loading } from "assets/images/icons/Loading.svg"

import { ReactComponent as ImageIcon } from "assets/images/icons/ImageIcon.svg"
import { ReactComponent as Video } from "assets/images/icons/Video.svg"
import { ReactComponent as File } from "assets/images/icons/File.svg"

import { ReactComponent as PriorityLow } from "assets/images/icons/PriorityLow.svg"
import { ReactComponent as PriorityMedium } from "assets/images/icons/PriorityMedium.svg"
import { ReactComponent as PriorityHight } from "assets/images/icons/PriorityHight.svg"

import { ReactComponent as Tag } from "assets/images/icons/Tag.svg"
import { ReactComponent as Headset } from "assets/images/icons/Headset.svg"
import { ReactComponent as User2 } from "assets/images/icons/User2.svg"

import { ReactComponent as FAQ } from "assets/images/icons/FAQ.svg"
import { ReactComponent as Return } from "assets/images/icons/Return.svg"
import { ReactComponent as Restore } from "assets/images/icons/Restore.svg"

import { ReactComponent as Exclamation } from "assets/images/icons/Exclamation.svg"
import { ReactComponent as Printer } from "assets/images/icons/Printer.svg"
import { ReactComponent as Expend } from "assets/images/icons/Expend.svg"
import { ReactComponent as Compres } from "assets/images/icons/Compres.svg"
import { ReactComponent as LocationMark } from "assets/images/icons/LocationMark.svg"
import { ReactComponent as ScannerGun } from "assets/images/icons/ScannerGun.svg"
import { ReactComponent as Clipboard } from "assets/images/icons/Clipboard.svg"
import { ReactComponent as Success } from "assets/images/icons/Success.svg"
import { ReactComponent as Package } from "assets/images/icons/Package.svg"
import { ReactComponent as BannerUnchecked } from "assets/images/icons/BannerUnchecked.svg"
import { ReactComponent as BannerChecked } from "assets/images/icons/BannerChecked.svg"
import { ReactComponent as Play } from "assets/images/icons/Play.svg"
import { ReactComponent as AnglesDown } from "assets/images/icons/AnglesDown.svg"
import { ReactComponent as Car } from "assets/images/icons/car.svg"
import { ReactComponent as Inbox } from "assets/images/icons/inbox.svg"
import { ReactComponent as Logistics } from "assets/images/icons/Logistics.svg"
import { ReactComponent as Link } from "assets/images/icons/Link.svg"
import { ReactComponent as Dollars } from "assets/images/icons/dollars.svg"
import { ReactComponent as Brand } from "assets/images/icons/Brand.svg"
import { ReactComponent as Link2 } from "assets/images/icons/Link2.svg"
import { ReactComponent as OpenBox } from "assets/images/icons/OpenBox.svg"
import { ReactComponent as FrontUser } from "assets/images/icons/FrontUser.svg"

import { ReactComponent as SigninChecked } from "assets/images/icons/SigninChecked.svg"
import { ReactComponent as Unchecked } from "assets/images/icons/Unchecked.svg"
import { ReactComponent as Show } from "assets/images/icons/Show.svg"
import { ReactComponent as Hidden } from "assets/images/icons/Hidden.svg"
import { ReactComponent as Chart } from "assets/images/icons/Chart.svg"
import { ReactComponent as StoreFilled } from "assets/images/icons/StoreFilled.svg"
import { ReactComponent as Tachometer } from "assets/images/icons/Tachometer.svg"
import { ReactComponent as CashFilled } from "assets/images/icons/CashFilled.svg"
import { ReactComponent as Logo } from "assets/images/icons/logo.svg"
import { ReactComponent as Profile } from "assets/images/icons/Profile.svg"
import { ReactComponent as Trophy } from "assets/images/icons/Trophy.svg"
import { ReactComponent as Message } from "assets/images/icons/Message.svg"
import { ReactComponent as Star } from "assets/images/icons/Star.svg"
import { ReactComponent as Creative } from "assets/images/icons/Creative.svg"
import { ReactComponent as Download } from "assets/images/icons/Download.svg"
import { ReactComponent as Type } from "assets/images/icons/Type.svg"
import { ReactComponent as View } from "assets/images/icons/View.svg"
import { ReactComponent as Locked } from "assets/images/icons/Locked.svg"
import { ReactComponent as Opened } from "assets/images/icons/Opened.svg"
import { ReactComponent as PeopleArrows } from "assets/images/icons/PeopleArrows.svg"
import { ReactComponent as ArrowLeft } from "assets/images/icons/ArrowLeft.svg"
import { ReactComponent as Lock2 } from "assets/images/icons/Lock2.svg"
import { ReactComponent as Telegram2 } from "assets/images/icons/Telegram2.svg"
import { ReactComponent as Camera } from "assets/images/icons/Camera.svg"
import { ReactComponent as TelegramFilled } from "assets/images/icons/TelegramFilled.svg"
import { ReactComponent as Code } from "assets/images/icons/Code.svg"
import { ReactComponent as Copy2 } from "assets/images/icons/Copy2.svg"
import { ReactComponent as WinnerFrame } from "assets/images/icons/winner-frame.svg"
import { ReactComponent as IconUser } from "assets/images/icons/IconUser.svg"
import { ReactComponent as Paid } from "assets/images/icons/Paid.svg"
import { ReactComponent as IconContestPositions } from "assets/images/icons/ContestPositions.svg"
import { ReactComponent as Bars } from "assets/images/icons/bars.svg"
import { ReactComponent as Reply } from "assets/images/icons/Reply.svg"
import { ReactComponent as Coin } from "assets/images/icons/Coin.svg"
import { ReactComponent as Building } from "assets/images/icons/Building.svg"
import { ReactComponent as SellerLogo } from "assets/images/icons/SellerLogo.svg"
import { ReactComponent as Email } from "assets/images/icons/Email.svg"
import { ReactComponent as Youtube } from "assets/images/icons/Youtube.svg"
import { ReactComponent as PhoneFilled } from "assets/images/icons/PhoneFilled.svg"
import { ReactComponent as InstagramFilled } from "assets/images/icons/InstagramFilled.svg"
import { ReactComponent as OrderSuccess } from "assets/images/icons/OrderSuccess.svg"
import { ReactComponent as More } from "assets/images/icons/More.svg"
import { ReactComponent as Withdraw } from "assets/images/icons/Withdraw.svg"
import { ReactComponent as AdvInfo } from "assets/images/icons/AdvInfo.svg"
import { ReactComponent as AdvRequest } from "assets/images/icons/AdvRequest.svg"
import { ReactComponent as AdvExpend } from "assets/images/icons/AdvExpend.svg"
import { ReactComponent as Logout } from "assets/images/icons/Logout.svg"
import { ReactComponent as AdvCategory } from "assets/images/icons/AdvCategory.svg"
import { ReactComponent as QulayXarid } from "assets/images/icons/QulayXarid.svg"

export const Icons = {
  QulayXarid,
  AdvCategory,
  Logout,
  AdvExpend,
  AdvRequest,
  AdvInfo,
  Withdraw,
  More,
  OrderSuccess,
  PhoneFilled,
  InstagramFilled,
  Youtube,
  Email,
  SellerLogo,
  Building,
  Coin,
  Reply,
  Bars,
  ArrowLeft,
  Lock2,
  Telegram2,
  Camera,
  TelegramFilled,
  Code,
  Copy2,
  WinnerFrame,
  IconUser,
  Paid,
  IconContestPositions,
  Locked,
  Opened,
  PeopleArrows,
  View,
  Download,
  Type,
  Creative,
  Star,
  Message,
  Trophy,
  Profile,
  Logo,
  CashFilled,
  Tachometer,
  Chart,
  StoreFilled,
  Show,
  Hidden,
  Unchecked,
  SigninChecked,
  FrontUser,
  OpenBox,
  Link2,
  Brand,
  Dollars,
  Link,
  Logistics,
  Inbox,
  Car,
  AnglesDown,
  Play,
  BannerUnchecked,
  BannerChecked,
  Package,
  Success,
  Clipboard,
  ScannerGun,
  LocationMark,
  Expend,
  Compres,
  Printer,
  Exclamation,
  Restore,
  Return,
  FAQ,
  User2,
  Headset,
  Tag,
  PriorityLow,
  PriorityMedium,
  PriorityHight,
  Video,
  File,
  ImageIcon,
  Loading,
  LockOpen,
  Lock,
  Send,
  Bell,
  Cash,
  Drag,
  List,
  Copy,
  Info,
  Sale,
  Spam,
  Exit,
  Taxi,
  Plus,
  User,
  Edit,
  Pause,
  Clock,
  Check,
  Truck,
  Phone,
  Group,
  Store,
  Minus,
  XIcon,
  Globe,
  Ticket,
  Clock2,
  ListOL,
  XLight,
  IdCard,
  Anchor,
  Target,
  Delete,
  Search,
  Filter,
  NoData,
  Eraser,
  Upload,
  October,
  Bitcoin,
  CircleX,
  History,
  Location,
  LifeRing,
  Category,
  Comments,
  Settings,
  UserIcon,
  PagePlus,
  ListPlus,
  FileMinus,
  AngleDown,
  Dashboard,
  FileCheck,
  ChartArea,
  TableList,
  PackerCoin,
  Calculator,
  Mastercard,
  BoxArchive,
  RecordList,
  Permission,
  FileLoading,
  DoubleLines,
  PackerDonate,
  PackerChecked,
  PackerHistory,
  DoubleForward,
  ArrowLeftShort,
  PackerCategory,
  LocationMarker,
  ArrowRightLong,
  PackerUnchecked,
  CheckedWithCircle,
}
