/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nonoctal-decimal-escape */
import { Icons } from "components"

import "./signin2.scss"
import { Form } from "containers"
import ReactInputMask from "react-input-mask"
import { get } from "lodash"
import { useState } from "react"
import { notification } from "services"
import { useNavigate } from "react-router-dom"
import Navbar from "Front/Layout/Navbar"
import { useQueryClient } from "@tanstack/react-query"

function Signin2() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [hasCode, setHasCode] = useState(false)

  let url = ""

  url = hasCode ? "verify-code" : "sms-login"

  return (
    <div className="page-signin">
      <Navbar />
      <main>
        <Form
          url={url}
          route="auth"
          onSuccess={(data) => {
            const isAdv = window.location.pathname.includes("admin")
            const redirectUrl = isAdv ? "/admin" : "/dashboard"
            window.localStorage.removeItem("profile")
            const token = get(data, "data")
            if (get(data, "data")) window.localStorage.setItem("token", token)

            if (!hasCode) {
              setHasCode(true)
              notification.success("SMS kod jo'natildi")
            }
            if (hasCode) {
              if (!isAdv) queryClient.fetchQuery(["getme"])
              queryClient.setQueryData(["globalState"], (old) => ({
                ...old,
                isAuth: true,
                isAdminAuth: true,
              }))
              navigate(redirectUrl)
            }
          }}
          fields={[
            {
              name: "phone",
              submitValue: (val) =>
                val
                  ? val.replace(")", "").replace("(", "").replaceAll("-", "").replaceAll(" ", "")
                  : null,
            },
            {
              name: "confirm",
              required: hasCode,
              submitValue: () => null,
            },
          ]}
        >
          {({ register, getValues, watch, setValue, isLoading }) => {
            watch()
            register("confirm")
            const confirm = get(getValues(), "confirm")
            return (
              <>
                <h1>Reklamachilar kabinetiga kirish</h1>
                <div className="form-input mb-20">
                  <label htmlFor="phone">Telefon raqamingiz</label>
                  <ReactInputMask
                    type="tel"
                    mask="+\9\9\8 (99) 999-99-99"
                    maskChar={null}
                    placeholder="+998 (XX) XXX-XX-XX"
                    id="phone"
                    autoComplete="off"
                    onChangeCapture={(val) => {
                      setValue("phone", val.target.value)
                      setValue("username", val.target.value)
                    }}
                    value={get(getValues(), "phone")}
                  />
                </div>
                {hasCode ? (
                  <div className="form-input mb-10">
                    <label>SMS kod</label>
                    <input
                      type="number"
                      {...register("code")}
                      autoComplete="off"
                      placeholder="SMS kodni kiriting"
                      className="sms-code"
                      disabled={!hasCode}
                    />
                  </div>
                ) : null}
                <div
                  className="form-checkbox"
                  onClick={() => setValue("confirm", !get(getValues(), "confirm"))}
                >
                  <div className="checkbox">
                    {get(getValues(), "confirm") ? <Icons.SigninChecked /> : <Icons.Unchecked />}
                  </div>
                  <label>
                    Men{" "}
                    <a
                      href="https://api.100k.uz/offers/oferta-shartnoma-admin.pdf"
                      className="h-underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      foydalanish shartlari
                    </a>{" "}
                    bilan tanishdim
                  </label>
                </div>
                {!hasCode ? (
                  <button type="submit" className="primary" disabled={isLoading}>
                    SMS kodni olish
                  </button>
                ) : (
                  <button type="submit" className="primary" disabled={!confirm || isLoading}>
                    Tizimga kirish
                  </button>
                )}
              </>
            )
          }}
        </Form>

        <div className="footer">
          <div className="footer__links">
            <a href="https://100k.uz/pages/privacy-policy">Maxfiylik siyosati</a>
            <a href="https://100k.uz/pages/privacy-policy">Foydalanish shartlari</a>
          </div>
          <p>Copyright © 2020 Barcha huquqlar himoyalangan</p>
        </div>
      </main>
    </div>
  )
}

export default Signin2
