import { useLocation, useParams } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { Icons } from "components"
import { useRequest } from "hooks"
import { find, get } from "lodash"
import { helpers } from "services"

import "./styles.scss"
import constants from "store/constants"
import FreeDeliveryCoin from "components/FreeDeliveryCoin"

function GiveCheck() {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const loaction = useLocation()
  const data = queryClient.getQueryData([`orders/${id}`]) || {
    data: { data: queryClient.getQueryData(["orderOne"]) },
  }
  const items = get(data, "data.data.order_items") || []
  const productBaseUrl =
    process.env.REACT_APP_SHOP_PRODUCT_BASE_URL || "https://100k.uz/shop/product/"
  useRequest({
    url: `orders/${id}`,
    enabled: false,
  })

  useRequest({
    url: `orders/${id}/items`,
    enabled: false,
  })

  const shippingType = find(constants.shippingTypes, {
    value: get(data, "data.data.price.shipping_type"),
  })

  const color = get(shippingType, "value") === "external" ? "bg-green" : "bg-blue"

  return loaction.pathname.includes("order/update") ? (
    <div className="give-check give-check__bg" style={{ maxWidth: "400px" }}>
      <div className="status-card">
        <div className="status-card__top">
          <h1>
            <strong>#{get(data, "data.data.id")}</strong> -{" "}
          </h1>
          <p className="status-text">{get(data, "data.data.status_label")}</p>
          {get(data, "data.data.packed_at") ? <Icons.Package className="packed" /> : null}
        </div>
        <ul>
          {items.map((item) => (
            <li key={get(item, "id")}>
              <div className="title">
                <a
                  href={productBaseUrl + get(item, "product.slug")}
                  target="_blank"
                  rel="noreferrer"
                  className={`${
                    get(item, "product_item.quantity") === 0 ? "c-black txt-strong" : ""
                  }`}
                >
                  <FreeDeliveryCoin fee={get(item, "total_admin_fee") / get(item, "quantity")} />
                  {get(item, "product_title")}
                </a>{" "}
                - <span className="c-red">{get(item, "quantity")}ta</span>
              </div>
              <p className="c-red item-price">{helpers.toReadeable(get(item, "price"))}</p>
            </li>
          ))}
          <li className={color}>
            <div className="title">
              <p>Dostavka summasi</p>
            </div>
            <p>{helpers.toReadeable(get(data, "data.data.price.shipping_price"))}</p>
          </li>
        </ul>
      </div>
      <h1 className="price" key={get(data, "data.data.price.total")}>
        <span className="c-red">{helpers.toReadeable(get(data, "data.data.price.total"))}</span>{" "}
        so&apos;m,
      </h1>

      {get(data, "data.data.is_paid") ? (
        <div className="paid">
          <Icons.Cash />
          <p>oldindan to&apos;langan</p>
        </div>
      ) : null}
    </div>
  ) : null
}

export default GiveCheck
