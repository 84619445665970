/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import { useFormContext } from "react-hook-form"

import "./input.scss"
import cn from "classnames"
import { get, isString } from "lodash"
import { helpers } from "services"

function formatter(num) {
  const result = helpers.toReadeable(num).replaceAll(",", " ")
  return result === "0" ? "" : result
}

function Input({
  type,
  name,
  label,
  required,
  className,
  onChange = () => {},
  extraBtn,
  canMinus,
  ...props
}) {
  const { register, watch, setValue, formState, getValues } = useFormContext()
  const { ref } = register(name)
  watch(name, "")
  const value = getValues(name)
  const { errors } = formState
  const handleChange = (e) => {
    onChange(e.target.value)
    if (type === "number") {
      const number = e.target.value.replaceAll(" ", "")
      if (!Number.isNaN(Number(number)) || number === "") {
        setValue(name, Number(number))
      }
    } else {
      setValue(name, e.target.value)
    }
  }

  const formatted = formatter(isString(value) ? Number(value.replaceAll(" ", "")) : value)

  return (
    <div className="form-group">
      {label ? (
        <label className={`form-label ${required ? "required" : ""}`} htmlFor="input">
          {label}
        </label>
      ) : null}

      <div className={`form-group__input-wrap ${extraBtn ? "--has-extra" : ""}`}>
        <input
          ref={ref}
          name={name}
          className={`form-control ${className} ${type}`}
          autoComplete="off"
          onChange={handleChange}
          value={type === "number" ? formatted : value}
          {...props}
        />
        <div className="extra-btn">{extraBtn}</div>
      </div>
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default Input
