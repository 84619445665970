/* eslint-disable react/no-danger */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-nonoctal-decimal-escape */
import { Form } from "containers"
import { Swiper, SwiperSlide } from "swiper/react"
import { Link, useLocation, useParams } from "react-router-dom"
import { Fields, Icons, Modal } from "components"

import ProductList from "Front/Components/ProductList"
import { useRequest } from "hooks"
import { get } from "lodash"
import { helpers } from "services"
import { useEffect, useState } from "react"

import "swiper/css"
import "./styles.scss"
import Breadcrumb from "Front/Components/Breadcrumb"

function ProductView() {
  const location = useLocation()
  const [swiper, setSwiper] = useState()
  const { id: slug, streamId } = useParams()
  const [isSuccess, setIsSuccess] = useState(false)
  const [itemPrice, setItemPrice] = useState(0)

  const product = useRequest({
    url: `products/${slug}`,
    route: "shop/v1",
    enabled: !!slug,
  })

  const streamProduct = useRequest({
    url: `streams/${streamId}`,
    route: "",
    enabled: !!streamId,
  })

  const {
    title,
    price,
    description,
    image = "",
    video,
    images,
    id,
    items,
  } = get(product.data, "data") || get(streamProduct.data, "data.product") || {}

  const productImages = images || []
  const productItems = items || []

  const similar = useRequest({
    url: `products/${id}/similar`,
    route: "shop/v1",
    enabled: false,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const form = (
    <Form
      key={String(id)}
      url={`stores/${process.env.REACT_APP_STORE_ID}/orders`}
      route="v1/seller"
      className="form-order"
      initialValues={{
        product_id: id,
        product_item_id: productItems[0],
        ...(streamId ? { stream_id: streamId } : {}),
      }}
      onSuccess={() => {
        setIsSuccess(true)
      }}
      fields={[
        {
          name: "customer_phone",
          submitValue: (val) =>
            String(val).replaceAll(" ", "").replaceAll("-", "").replace("(", "").replace(")", ""),
        },
        {
          name: "product_item_id",
          type: "object",
          submitValue: (option) => get(option, "id"),
        },
      ]}
    >
      {({ isLoading }) => (
        <>
          {productItems.length > 1 ? (
            <div className="row mb-20">
              <div className="col">
                <Fields.RadioButtons
                  label="Toifalar:"
                  name="product_item_id"
                  options={productItems}
                  optionLabel="name"
                  optionValue="id"
                  onClick={(data) => setItemPrice(get(data, "price"))}
                />
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col">
              <Fields.Input name="client_full_name" placeholder="Ismingiz" />
            </div>
            <div className="col">
              <Fields.InputMask
                name="customer_phone"
                mask="+\9\9\8 99 999 99 99"
                placeholder="+998 90 000 00 00"
                alwaysShowMask={false}
              />
            </div>
          </div>
          <div className="center">
            <button type="submit" className="btn-order" disabled={isLoading}>
              Buyurtma berish
            </button>
          </div>
        </>
      )}
    </Form>
  )

  return (
    <div className="page-product-view">
      <div className="breadcrumb-container container mt-20">
        <Breadcrumb text={title} />
      </div>
      <span className="form-mobile">{form}</span>
      <section>
        <div className="container mb-20 top-container">
          <div className="top">
            <div className="images-wrapper">
              {product.isLoading && streamProduct.isLoading ? (
                <>
                  <div className="main-image">
                    <Swiper
                      slidesPerView={1}
                      onInit={(s) => {
                        setSwiper(s)
                      }}
                    >
                      {[image, ...productImages].map((item, i) => (
                        <SwiperSlide key={i}>
                          <div className="img sk"> </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <ProductImages
                    data={[image, ...productImages]}
                    swiper={swiper}
                    isLoading={product.isLoading && streamProduct.isLoading}
                  />
                </>
              ) : (
                <>
                  <div className="main-image">
                    <Swiper
                      slidesPerView={1}
                      onInit={(s) => {
                        setSwiper(s)
                      }}
                    >
                      {video ? (
                        <SwiperSlide>
                          <video muted src={video} controls />
                        </SwiperSlide>
                      ) : null}
                      {[image, ...productImages].map((item, i) => (
                        <SwiperSlide key={i}>
                          <img src={item} alt="" />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <ProductImages data={[image, ...productImages]} swiper={swiper} />
                </>
              )}
            </div>
            <div className="top-right">
              <h2 className="product-title">{title}</h2>
              <p className="product-price">{helpers.toReadeable(itemPrice || price)} so'm</p>
              <span className="form-desktop">{form}</span>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="center">
            <h4>Maxsulot Haqida</h4>
          </div>
          <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        {get(similar.data, "data", []).length > 0 && (
          <div className="center mt-20">
            <h4>O‘xshash mahsulotlar</h4>
          </div>
        )}
      </section>
      {get(similar.data, "data", []).length > 0 && (
        <ProductList hasPagination={false} data={similar.data} />
      )}
      <Modal
        isOpen={isSuccess}
        handleClose={() => setIsSuccess(false)}
        className="modal-success"
        outsideClick={false}
        maxWidth={630}
      >
        <p className="title">Buyurtmangiz qabul qilindi !</p>
        <p className="desc">
          Operatorlar tez orada siz bilan bog’lanishadi. iltimos kiritgan telefoningizni yoniq yolda
          qoldiring!
        </p>
        <Icons.OrderSuccess />
        <Link
          to="/"
          className="btn-success"
          onClick={() => {
            document.body.style.overflow = "auto"
            setIsSuccess(false)
          }}
        >
          Bosh sahifaqa qaytish
        </Link>
      </Modal>
    </div>
  )
}

export default ProductView

function ProductImages({ data, swiper, isLoading, hasVideo }) {
  if (isLoading)
    return (
      <div className="product-images">
        {Array(5)
          .fill(1)
          .map((_, i) => (
            <div className="img sk" key={i}>
              {" "}
            </div>
          ))}
      </div>
    )
  return (
    <div className="product-images">
      {data.map((item, i) => (
        <img src={item} alt="" onClick={() => swiper.slideTo(hasVideo ? i + 1 : i)} />
      ))}
    </div>
  )
}
