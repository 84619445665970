const { lazy } = require("react")

const Pages = {
  Profile: lazy(() => import("Admin/Pages/Profile/UpdateProfile")),
  ProfilePenaltyReply: lazy(() => import("Admin/Pages/Profile/PenaltyReply")),

  Forbidden: lazy(() => import("Admin/Pages/Auth/Forbidden")),

  UserList: lazy(() => import("Admin/Pages/User/List")),
  UserCreate: lazy(() => import("Admin/Pages/User/Create")),

  AdminList: lazy(() => import("Admin/Pages/Admin/List")),

  ProductList: lazy(() => import("Admin/Pages/Product/List")),
  ProductCreate: lazy(() => import("Admin/Pages/Product/Create")),
  ProductUpdate: lazy(() => import("Admin/Pages/Product/Update")),

  WithdrawList: lazy(() => import("Admin/Pages/Withdraws/List")),

  TransactionList: lazy(() => import("Admin/Pages/Transaction/List")),

  IncomeList: lazy(() => import("Admin/Pages/Income/List")),

  OutgoingList: lazy(() => import("Admin/Pages/Outgoing/List")),

  StreamList: lazy(() => import("Admin/Pages/Stream/List")),
  StreamUpdate: lazy(() => import("Admin/Pages/Stream/Update")),

  SaleList: lazy(() => import("Admin/Pages/Sale/List")),

  OrderList: lazy(() => import("Admin/Pages/Order/List")),
  OrderCreate: lazy(() => import("Admin/Pages/Order/Create")),
  OrderUpdate: lazy(() => import("Admin/Pages/Order/Update")),

  Settings: lazy(() => import("Admin/Pages/Settings")),

  Statistics: lazy(() => import("Admin/Pages/Statistics")),

  PrintOrders: lazy(() => import("Admin/Pages/PrintOrders")),

  AccountingList: lazy(() => import("Admin/Pages/Accounting/List")),
  AccountingCreate: lazy(() => import("Admin/Pages/Accounting/Create")),

  CancelledIncomeList: lazy(() => import("Admin/Pages/Disposal/List")),

  AccountHistoryList: lazy(() => import("Admin/Pages/AccountHistory/List")),

  Profile2: lazy(() => import("Advertiser/Pages/Profile2")),
  Profile2Account: lazy(() => import("Advertiser/Pages/Profile2/Account")),
  Profile2Telegram: lazy(() => import("Advertiser/Pages/Profile2/Telegram")),
  Profile2TargatApi: lazy(() => import("Advertiser/Pages/Profile2/TargetApi")),
}

export default Pages
