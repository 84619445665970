import { Icons, Modal } from "components"
import useGlobalState from "hooks/useGlobalState"
import { useEffect, useState } from "react"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import { Emitter, notification } from "services"
import { useMutate } from "hooks"
import useStore from "hooks/useStore"
import { get } from "lodash"

// navbarni mobilnida sonini styledan o'zgartirish kerak

const navs = [
  {
    to: "/admin",
    text: "Dashboard",
    icon: <Icons.Tachometer />,
  },
  {
    to: "/admin/market",
    text: "Market",
    icon: <Icons.StoreFilled />,
  },
  {
    to: "/admin/stream",
    text: "Oqim",
    icon: <Icons.Link />,
  },
  {
    to: "/admin/stats",
    text: "Statistika",
    icon: <Icons.Chart />,
  },
  {
    to: "/admin/payment",
    text: "To'lov",
    icon: <Icons.CashFilled />,
    onClick: () => Emitter.emit("profile:refresh"),
  },
  {
    to: "/admin/request",
    text: "So'rovlar",
    icon: <Icons.AdvRequest />,
  },
  {
    to: "/admin/profile",
    text: "Shaxsiy ma’lumotlar",
    icon: <Icons.User2 />,
  },
  {
    to: "/admin/profile/telegram",
    text: "Telegram bilan bog’lash",
    icon: <Icons.Telegram2 />,
  },
  {
    to: "/admin/profile/targat-api",
    text: "Target uchun API",
    icon: <Icons.Code />,
  },
]

export const dropdownList = [
  {
    text: "Profil",
    to: "/admin/profile",
  },
]

function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()
  const store = useStore()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [, setState] = useGlobalState()

  const { mutate, isLoading } = useMutate({
    url: "v1/passport/logout",
    onSuccess: () => {
      const isAdv = window.location.pathname.includes("admin")
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      localStorage.removeItem("advUser")

      setState(isAdv ? { isAuth: false } : { isAdminAuth: false })
      navigate(isAdv ? "/admin/signin" : "/dashboard/signin")
      notification.success()
    },
  })

  const signOut = () => {
    if (!isLoading) {
      mutate({
        data: {
          fcm_token: localStorage.getItem("token"),
        },
      })
    }
  }

  useEffect(() => {
    setMobileMenuOpen(false)
  }, [location.pathname])

  return (
    <div className="adv-navbar">
      <div className="navbar-wrapper">
        <div className="top f-c-sb">
          <Link to="/">
            <img src={get(store, "logo_path")} alt="" className="logo" />
          </Link>
          <div className="link-admin mobile" onClick={() => setMobileMenuOpen(true)}>
            <Icons.FrontUser />
          </div>
        </div>
        <ul className="navigation">
          {navs.map(({ to, text, icon, onClick = () => {} }) => (
            <li key={text} onClick={onClick}>
              <NavLink to={to} className={() => window.location.pathname === to && "active"}>
                <div className="dashboard-icon">{icon}</div>
                <p>{text}</p>
                <span> </span>
              </NavLink>
            </li>
          ))}
        </ul>

        <div
          className="menu-item"
          onClick={(e) => {
            e.stopPropagation()
            navigate("/")
          }}
        >
          <Icons.Store />
          <p>Do‘konga o‘tish</p>
        </div>
        <div
          className="menu-item"
          onClick={(e) => {
            e.stopPropagation()
            signOut()
          }}
        >
          <Icons.Logout />
          <p>Profildan chiqish</p>
        </div>
      </div>
      <div className="adv-navbar__mobile">
        <ul className="adv-navbar__mobile-content">
          {navs.map(({ to, text, icon, onClick = () => {} }) => (
            <li key={text} onClick={onClick}>
              <NavLink to={to}>
                <div className="dashboard-icon">{icon}</div>
                <p>{text}</p>
              </NavLink>
            </li>
          ))}
          <li>
            <NavLink to="/">
              <div className="dashboard-icon">
                <Icons.Store />
              </div>
              <p>Websayt</p>
            </NavLink>
          </li>
        </ul>
      </div>

      <Modal
        isOpen={mobileMenuOpen}
        handleClose={() => {
          setMobileMenuOpen(false)
        }}
        className="modal-mobile-menu"
        hasOutsideClick
      >
        <div
          className="icon-close"
          onClick={() => {
            setMobileMenuOpen(false)
          }}
        >
          <Icons.XIcon />
        </div>

        <div className="items">
          {navs.slice(5).map(({ text, icon, to }) => (
            <div
              className="item"
              onClick={(e) => {
                e.stopPropagation()
                navigate(to)
              }}
            >
              {icon}
              <p>{text}</p>
            </div>
          ))}
          <div
            className="item"
            onClick={(e) => {
              e.stopPropagation()
              navigate("/")
            }}
          >
            <Icons.Store />
            <p>Do‘konga o‘tish</p>
          </div>
          <div
            className="item"
            onClick={(e) => {
              e.stopPropagation()
              signOut()
            }}
          >
            <Icons.Logout />
            <p>Profildan chiqish</p>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Navbar
